import { default as indexzWTAGjBYJrMeta } from "/var/www/nuxt-v3/prod/releases/17/pages/index.vue?macro=true";
import { default as simulation_45pineliMczTyqbBcMeta } from "/var/www/nuxt-v3/prod/releases/17/pages/simulateurs-immobilier/simulation-pinel.vue?macro=true";
import { default as simulation_45ptze6D5LTjOgbMeta } from "/var/www/nuxt-v3/prod/releases/17/pages/simulateurs-immobilier/simulation-ptz.vue?macro=true";
export default [
  {
    name: indexzWTAGjBYJrMeta?.name ?? "index",
    path: indexzWTAGjBYJrMeta?.path ?? "/",
    meta: indexzWTAGjBYJrMeta || {},
    alias: indexzWTAGjBYJrMeta?.alias || [],
    redirect: indexzWTAGjBYJrMeta?.redirect,
    component: () => import("/var/www/nuxt-v3/prod/releases/17/pages/index.vue").then(m => m.default || m)
  },
  {
    name: simulation_45pineliMczTyqbBcMeta?.name ?? "simulateurs-immobilier-simulation-pinel",
    path: simulation_45pineliMczTyqbBcMeta?.path ?? "/simulateurs-immobilier/simulation-pinel",
    meta: simulation_45pineliMczTyqbBcMeta || {},
    alias: simulation_45pineliMczTyqbBcMeta?.alias || [],
    redirect: simulation_45pineliMczTyqbBcMeta?.redirect,
    component: () => import("/var/www/nuxt-v3/prod/releases/17/pages/simulateurs-immobilier/simulation-pinel.vue").then(m => m.default || m)
  },
  {
    name: simulation_45ptze6D5LTjOgbMeta?.name ?? "simulateurs-immobilier-simulation-ptz",
    path: simulation_45ptze6D5LTjOgbMeta?.path ?? "/simulateurs-immobilier/simulation-ptz",
    meta: simulation_45ptze6D5LTjOgbMeta || {},
    alias: simulation_45ptze6D5LTjOgbMeta?.alias || [],
    redirect: simulation_45ptze6D5LTjOgbMeta?.redirect,
    component: () => import("/var/www/nuxt-v3/prod/releases/17/pages/simulateurs-immobilier/simulation-ptz.vue").then(m => m.default || m)
  }
]